import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <p>
          AZ Saber
        </p>

        <p>
          We meet every Monday and Thursday from 7pm to 9pm. We accept new members from 7pm to 7:30pm.
        </p>

        <p>
          No saber required. Feel free to just show up and we'll get you going from there!
        </p>

        Socials<a target="_blank" href="https://www.facebook.com/ASUSaber">Facebook</a> <a target="_blank" href="https://www.instagram.com/az__saber/">Instagram</a>
        
        <p>Location</p>
        <iframe src="https://www.google.com/maps/embed?pb=!1m16!1m12!1m3!1d3330.090281037269!2d-111.93586623819125!3d33.42089055069163!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!2m1!1stooker%20lawn%20asu!5e0!3m2!1sen!2sus!4v1698616246536!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

        <p>Backup Location</p>
        <p>If there is no one there during our normal hours we will be here</p>

        <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1136.9145393709327!2d-111.93488479039434!3d33.419139820270765!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x872b08db9c12270f%3A0x1def0e53fc1a9a3a!2sHayden%20Lawn%2C%20Tempe%2C%20AZ%2085287!5e0!3m2!1sen!2sus!4v1698618264920!5m2!1sen!2sus" width="600" height="450" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
      </header>
    </div>
  );
}

export default App;
